.hh-switch {
    display: flex;
    align-items: center;
    width: auto;

    .hh-switch-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 3rem;
        background-color: #e2e2e2;
        padding: 3px 6px;
        min-width: 54px;

        .marcador {
            display: inline-block;
            position: absolute;
            left: calc(50% - 9px);
            width: 16px;
            height: 16px;
            margin-top: 0px;
            border-radius: 16px;
            background-color: $white;
            box-shadow: -5px 6px 5px -3px rgba(0, 0, 0, 0.1);
            transition: all 0.2s ease-out;

            &.checado {
                transform: translateX(-14px);
                transition: transform 0.2s ease-out;
            }
            
            &.nao-checado {
                transform: translateX(16px);
                transition: transform 0.2s ease-out;
            }
        }

        &.checado {
            background-color: $success;
            transition: all 0.2s ease-out;
        }

        &.nao-checado:not(.corPadrao) {
            background-color: $danger;
            transition: all 0.2s ease-out;
        }
    }

    label {
        margin-left: 8px;
    }
}